import React, { useCallback, useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { styled } from "styled-components";

import { Button, Card, Col, Select, Input, Row, message } from "antd";
import axiosInstance from "../../../axiosInterceptor";
import { Option } from "antd/es/mentions";
import botApiUrl from "../../../botApiUrl";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Servidor = ({ recarregarServer, server, setServer }) => {
  const dispatch = useDispatch();
  const accessToken = window.localStorage.getItem("accessToken");
  const idUser = window.localStorage.getItem("idUser");
  const [loading, setLoading] = useState(true);
  const [idLoja, setIDLoja] = useState(null);
  const [qrs, setQrs] = useState([]);



  useEffect(() => {
        setServer(window.localStorage.getItem("server"));
    }, [recarregarServer])
  


  useEffect(() => {
    if (!idUser) return;
    const fetchData = async (page, pageSize) => {
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      };
      if (!idLoja) {
        const resp = await botApiUrl.get(`https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`, options);
        setIDLoja(resp.data.id);
      }
    };
    fetchData(0, 20);
  }, [idUser]);

  
  useEffect(() => {
    if (!accessToken) return;
    const fetchData = async () => {
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      };
        const resp = await botApiUrl.get(`v1/mensagem2/get`, options);


        if (resp.data && typeof resp.data === 'object') {   
        console.log(resp.data);
        setQrs(Object.values(resp.data));
        } else {
          setQrs([]);
        }

    };
    fetchData();
  }, [accessToken,recarregarServer]);



  const handleServerChange = (value) => {
    console.log(value);
    setServer(value);
}


  const handleSave = () => {
    if (server) {
      window.localStorage.setItem('server', server);
      message.success('Servidor salvo com sucesso!');
    } else {
      message.error('Por favor, selecione um servidor antes de salvar.');
    }
  };

  return (
    <Container>
      <center>
        <Card style={{ width: "80%", marginTop: "150px" }}   title="Servidor">
          <Row>
            <Col xs={24} lg={24}>
              <FormContainer>
                <Select
                  style={{ width: "200px" }}
                  placeholder="Selecione uma linha..."
                  value={server}
                  onChange={handleServerChange}
                  loading={loading}
                >
                  {qrs.map((opcao) => (
                    <Option key={opcao.aplicacao} value={opcao.aplicacao}>
                      {opcao.aplicacao}
                    </Option>
                  ))}
                </Select>
                <Button
                  type="primary"
                  style={{ marginTop: '16px', backgroundColor: 'black', borderColor: 'black' }}
                  onClick={handleSave}
                >
                  <FontAwesomeIcon icon={faSave} style={{ color: 'white', marginRight: '8px' }} />
                  Salvar
                </Button>
              </FormContainer>
            </Col>
          </Row>
        </Card>
      </center>
    </Container>
  );
}
const Container = styled.div`
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default Servidor;
