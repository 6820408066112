import React, { useState, useEffect, useCallback, useRef } from "react";
import camuflado from "../../../fundo.webp";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { AndroidOutlined, AppleOutlined } from "@ant-design/icons";
import { Col, Card, Row, Typography,  Select, Form, Skeleton, QRCode, Input, Button, notification, Image } from "antd";
import { format } from "date-fns";
import GraficoAnual from "../../graficos/GraficoAnual";
import GraficoTreino from "../../graficos/GraficoTreino";
import GraficoComp from "../../graficos/GraficoComp";
import axiosInstance from "../../../axiosInterceptor";
import axios from "axios";
import botApiUrl from "../../../botApiUrl";
import errorImage from '../../../escolhaservidor.webp';


const { Text } = Typography;

const Home = ({ recarregarDados }) => {
  const accessToken = window.localStorage.getItem("accessToken");

  const [loading, setLoading] = useState(false);
  const [loadingQR, setLoadingQR] = useState(false);
  const [loja, setLoja] = useState(null);
  const idUser = window.localStorage.getItem("idUser");
  const [idLoja, setIDLoja] = useState(null);


  const { Option } = Select;
  const [instancias, setInstancias] = useState([]);
  const [selectedInstancia, setSelectedInstancia] = useState(null);
  const [qrCode, setQrcode] = useState('');
  const [linhaEscolhida, setLinhaEscolhida] = useState(null);
  const intervalRef = useRef(null);
  const [reload, setReload] = useState(true);

  useEffect(() => {
    if(idUser){
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      };
       axiosInstance.get(
        `https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}` ,
        options
      ).then((resp)=>{
        setIDLoja(resp.data.id);
        setLoja(resp.data);
      })

    }
  }, [idUser]);

  useEffect(() => {
    const resetInterval = () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      intervalRef.current = setInterval(() => {
        handleInstanciaChange(selectedInstancia);
      }, 30000); 
    };

    resetInterval();

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    }
  }, [selectedInstancia]);


  useEffect(()=>{
    if(instancias.length > 0){
      handleInstanciaChange(instancias[0].id);
    }
  },[instancias])

  

  useEffect(() => {
    if (idUser && idLoja) {
      
      setLoading(true);
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      };
      botApiUrl.get(
     `/v1/sessao/buscarPorIdLojaLogado/${idLoja}` ,
        options
      ).then((resp)=>{
      
            const qtdLinhas = loja.linhasTechsbot;
            const diff = qtdLinhas - resp.data.length;
            const instanciasExtras = [...resp.data];
            if (diff > 0) {
              for (let i = 0; i < diff; i++) {
               instanciasExtras.push({ id: `${instancias.length + i}`, tel: `Linha livre ${instancias.length + i + 1}` });
              }
            }
            setInstancias(instanciasExtras); 
      console.log(instanciasExtras);

  
      }).catch((error) => {
        setLoading(false);
        if(error.response.status == 404){
        }
      });
    }
  }, [idUser, accessToken, reload, idLoja]);

  useEffect(() => {
    if (linhaEscolhida) {
      setLoading(false);
    }
  }, [linhaEscolhida]);

  const buscarQr = () =>{
    if (accessToken) {
      setLoadingQR(true);
      console.log('buscando QR');
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      };
      botApiUrl.get(
        `/v1/mensagem2/get/${window.localStorage.getItem("server")}` ,

        options
      ).then((resp)=>{
            setQrcode(resp.data.qr);
            setLoadingQR(false);
      })
    }
  }

  const handleInstanciaChange = (value) => {

    const linha = instancias.find(item => item.id === value);

    if(!linha?.idInstancia){ 
      setLoading(false);
      buscarQr();
    }else{
      setLinhaEscolhida(linha);
    }
    setSelectedInstancia(value);
  };

  function mostrarQR(id) {
    const linha = instancias.find(item => item.id === id);
    return linha?.idInstancia ?false:true;
  }

  const formatarTelefone = (phoneNumber) => {
    // Remove todos os caracteres não numéricos
    const cleaned = phoneNumber.replace(/\D/g, '');
  
    // Verifica se o número começa com 55 e tem 12 ou 13 dígitos
    if (cleaned.startsWith('55') && (cleaned.length === 12 || cleaned.length === 13)) {
      const numberWithoutCountryCode = cleaned.substring(2);
  
      if (numberWithoutCountryCode.length === 11) {
        // Formato para número de celular: +55 (xx) xxxxx-xxxx
        return numberWithoutCountryCode.replace(/^(\d{2})(\d{5})(\d{4})$/, '+55 ($1) $2-$3');
      } else if (numberWithoutCountryCode.length === 10) {
        // Formato para número fixo: +55 (xx) xxxx-xxxx
        return numberWithoutCountryCode.replace(/^(\d{2})(\d{4})(\d{4})$/, '+55 ($1) $2-$3');
      } else {
        // Se o número não tiver 10 ou 11 dígitos após o código do país, retorna o número original
        return phoneNumber;
      }
    } else {
      // Se o número não começar com 55 ou não tiver o comprimento correto, retorna o número original
      return phoneNumber;
    }
  };



  return (
    <Container>
      <Row
        gutter={16}
        style={{
          justifyContent: "center",
          marginTop: "70px",
          marginBottom: "80px",
        }}
      >
        <Card style={{ width: "800px", margin: "0 auto", padding: "20px" }}>
        <Form
        name="basic"
        layout="horizontal"
        initialValues={{ remember: true }}
        onFinish={async (dados)=>{
          try{
          console.log(dados);
        setLoading(true);
          const options = {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          };
      const response1 = await botApiUrl.put(
           `/v1/sessao/confirmarInstancia/${dados.codigo}/${idLoja}`,

        {},options
      );
      if(response1.status == 200){
        notification.success({
          message: "Sucesso",
          description: `Instancia Conectada!`,
        });
      
        // Adiciona um reload após 2 segundos
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
      setLoading(false);
    }
      catch (error) {
        console.error('Erro ao chamar a API:', error.status);
        console.error('Erro ao chamar a API:', error);
        notification.error({
          message: "Falha",
          description: `${error.response.data}`,
        });
        if(error.status == 404){
            console.error(`${error.status} Instancia não encontrada`);
        }
        setLoading(false);
        return ;
    }
        }}
        onFinishFailed={(errorInfo) =>{ console.log('Failed:', errorInfo);}}
        autoComplete="off"
      >
        <div style={{display:'flex', alignItems: 'baseline'}}>
          <Form.Item
          label="Código"
          name="codigo"
          rules={[{ required: true, message: 'Código obrigatório!' }]}
          style={{ width: '80%' }} 
        >
          <Input style={{ width: '100%' }} />
        </Form.Item>
        <Button
                style={{ width: '20%',backgroundColor:'#000' }}
                type={'primary'}
                htmlType={'submit'}
                loading={loading}
              >
                Confirmar
        </Button>
  </div>

      </Form>
        </Card>
        <Card style={{ width: "800px", margin: "0 auto", padding: "20px",marginTop:'10px' }}>
          <Row gutter={[16, 16]} align="middle">
            <Col xs={24} sm={24} style={{ textAlign: "center" }}>


            {!loading ?  <Form.Item label="Linhas:">
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Selecione uma linha..."
                      value={selectedInstancia}
                      onChange={handleInstanciaChange}
                    >
                      {instancias.map((instancia) => (
                        <Option key={instancia.id} value={instancia.id}>
                          {formatarTelefone(instancia.tel)}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item> : <Skeleton></Skeleton>}
      
            </Col>
            {mostrarQR(selectedInstancia) ? <Col xs={24} sm={24} style={{justifyContent: 'center',display: 'flex'}}>
            {qrCode ? (
  <QRCode value={qrCode} size={330} status={loadingQR ? "loading" : "active"} />
            ) : (
              <Image
              width={330}
              height={330}
              src={errorImage} // Use o caminho correto para sua imagem de erro
              alt="Erro de conexão"
              preview={false}
            />
            )}

            </Col> : !loading ? <center style={{width:'100%'}}>{`${linhaEscolhida?.id}`}<br/>{`${linhaEscolhida?.idInstancia}`}<br/>{`${formatarTelefone(linhaEscolhida?.tel)}`}<br/>{`${linhaEscolhida?.logado ? 'Ativo': 'Inativo'}`}</center> : <Skeleton></Skeleton>}

          </Row>
        </Card>
      </Row>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
`;

const TituloHabitualidade = styled.span`
  font-weight: bold;
  font-size: 18px;
  color: #000;
  margin-top: 20;
`;

const ContainerInterno = styled.div`
  display: flex;
  width: 80%;
`;

const GridContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #fff;
  height: 200;
  margin-top: 20;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 16;
  overflow: hidden;
`;
const GridItem = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
`;

const ViewCirculo = styled.div`
  width: 80%;
  height: 200px;
  background-color: white;
  border-radius: 50;
  border-width: 2px;
  border-color: black;
  margin: 20;
  align-items: center;
  justify-content: center;
  font-weight: bold;
`;

export default Home;
