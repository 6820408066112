import React, { useEffect, useState } from "react";
import { Card, Form, Input, Modal, Skeleton, Select, Col, Row, Button, Table, notification, InputNumber, Checkbox } from "antd";
import { format, parseISO } from "date-fns";
import axiosInstance from "../../../axiosInterceptor";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import botApiUrl from "../../../botApiUrl";

const CrudClube = ({ recarregarDados, arrayContatos,setArrayContatos,pagination,setPagination}) => {
  const accessToken = window.localStorage.getItem("accessToken");
  const idUser = window.localStorage.getItem("idUser");
  const [loading, setLoading] = useState(true);
  const [registros, setRegistros] = useState([]);
  const [idLoja, setIDLoja] = useState(null);
  const [selectedInstancia, setSelectedInstancia] = useState(null);
  const [instancias, setInstancias] = useState([]);
  const [selectedItems, setSelectedItems] = useState(new Set());
  const [selectedItemsAux, setSelectedItemsAux] = useState(new Set());
  const [textoSelecao, setTextoSelecao] = useState('');
  const [pagina, setPagina] = useState(1);
  const [atualizarArray, setAtualizarArray] = useState(true);

  const [visibleExcluir, setVisibleExcluir] = useState(false);
  const [visibleBloquear, setVisibleBloquear] = useState(false);

  const { Option } = Select;

  const history = useHistory();

  const [arrayFantasmaPagina, setArrayFantasmaPagina] = useState([]);
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [checkboxTodos, setCheckboxTodos] = useState(false);
  const [telefoneOuNomeTodos, setTelefoneOuNomeTodos]= useState('');
  const [buscaDias, setBuscaDias]= useState('');
  const [nomeOuTelefone, setNomeOuTelefone]= useState('');

  
const onChangeTodos = async (e) => {
  console.log(`${e.target.checked}`);
  setCheckboxTodos(e.target.checked);
  if(e.target.checked){
    console.log(selectedInstancia);
    console.log(telefoneOuNomeTodos);
    const options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
        if(selectedInstancia == 'TODOS'){
          const response = await axiosInstance.get(
            `https://deliverypdv.com.br/v1/contatos/todosLojaIds/${idLoja}`,
            options
          );
          setArrayContatos(response.data);

          console.log(response.data);
        }
        else{
          const response = await axiosInstance.get(
            `https://deliverypdv.com.br/v1/contatos/todosOrigemIds/${selectedInstancia}/${idLoja}`,
            options
          );
          setArrayContatos(response.data);

          console.log(response.data);
        }

 
  

  }else{
    setArrayContatos([]);
  }
};
  
  useEffect(() => {
    const keys = Object.keys(selectedItemsAux);
    const arrayContatosAux = [];
    keys.map((pagina) => {
      console.log(selectedItemsAux[pagina]);
      selectedItemsAux[pagina].map((contato)=>{
        arrayContatosAux.push(contato);
      })
     })
     setArrayContatos(arrayContatosAux);
     console.log('Passei pela contagem');
  }, [atualizarArray]);

  

  // Seleção da tabela
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    console.log(newSelectedRowKeys);
    console.log(selectedRowKeys);
    if(newSelectedRowKeys.length > selectedRowKeys.length){
      console.log('Adicionando novo elemento');
      setSelectedRowKeys(newSelectedRowKeys);
      const arrayContatosAux = arrayContatos;
      const adicionarContato = newSelectedRowKeys.filter(key => !arrayContatos.includes(key));
      if(adicionarContato.length > 0){
        adicionarContato.map((contato)=>{
          arrayContatosAux.push(contato);
        }) 
      
      }
      console.log(arrayContatosAux);
      setArrayContatos(arrayContatosAux);
    }else if(newSelectedRowKeys.length < selectedRowKeys.length){
      console.log('Removendo elemento');
      const removido = selectedRowKeys.filter(key => !newSelectedRowKeys.includes(key));
      setSelectedRowKeys(newSelectedRowKeys.filter(key => selectedRowKeys.includes(key)));
      
      if(removido.length> 0){
            console.log(arrayContatos);
            setArrayContatos(prevContatos => 
              prevContatos.filter(key => !removido.includes(key))
            ); 
      }

    }


 /*
  // arrayAux[pagination.current]=(newSelectedRowKeys);
    console.log('setSelectedItemsAux');
    console.log(arrayAux);
    setSelectedItemsAux(arrayAux);

    console.log('newSelectedRowKeys');
    console.log(newSelectedRowKeys);
    const newSelectedItems = new Set(selectedItems);
    newSelectedRowKeys.forEach(key => newSelectedItems.add(key));
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedItems(newSelectedItems);
    setAtualizarArray(!atualizarArray);*/
  };



  useEffect(() => {
    if (idLoja && accessToken) {
      setLoading(true);
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      };
      botApiUrl.get(
        `/v1/sessao/buscarPorIdLojaLogado/${idLoja}`,
        options
      ).then((resp) => {
        setInstancias(resp.data);
        setLoading(false);
      }).catch((error) => {
        console.log(error);
        setLoading(false);
      });
    }
  }, [accessToken, idLoja]);

 useEffect(() => {
    if (!idUser) return;

    const fetchData = async (page, pageSize) => {
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      };
      if (!idLoja) {
        const resp = await axiosInstance.get(`https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`, options);
        setIDLoja(resp.data.id);
      }
    };
    fetchData(0, 20);
  }, [idUser]);



  const handleTableChange = async (pagination) => {
    const { current, pageSize } = pagination;
    console.log(pagination);
    console.log(pagina);
    console.log(current);
    if(pagina !== current){
      setLoading(true);
      const response = await fetchData(current - 1, pageSize);
      setRegistros(response);
      console.log(response);
      const selectedRowKeysAux = [];
      response.content.map(contatoDaLista =>{
        arrayContatos.map((contatoDoArray)=>{
            if(contatoDaLista.key == contatoDoArray){
              selectedRowKeysAux.push(contatoDaLista.key);
            }
        })
      })
      setSelectedRowKeys(selectedRowKeysAux);
      setPagination({
        ...pagination,
        total: response.totalElements,
      });
      setLoading(false);
      setPagina(current);
    }

  };

  const buscarContatos = async (filtro) => {
    setLoading(true);
    const options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    if (filtro === 'TODOS') {
      const resp2 = await axiosInstance.get(`https://deliverypdv.com.br/v1/contatos/todosLoja/${idLoja}`, options);
      const registrosAux = [];
      resp2.data.content.map((data) => {
        registrosAux.push({
          key: data.id,
          ...data
        });
      });
      console.log(resp2.data);
      setRegistros({ content: registrosAux });
      setPagination({
        current: resp2.data.pageable.pageNumber + 1,
        pageSize: resp2.data.pageable.pageSize,
        total: resp2.data.totalElements,
      });
    } else {
      const resp2 = await axiosInstance.get(`https://deliverypdv.com.br/v1/contatos/todosOrigem/${filtro}/${idLoja}`, options);
      const registrosAux = [];
      resp2.data.content.map((data) => {
        registrosAux.push({
          key: data.id,
          ...data
        });
      });
      console.log(resp2.data);
      setRegistros({ content: registrosAux });
      setPagination({
        current: resp2.data.pageable.pageNumber + 1,
        pageSize: resp2.data.pageable.pageSize,
        total: resp2.data.totalElements,
      });
    }
    setLoading(false);
  };

  const formatarTelefone = (phoneNumber) => {
    const cleaned = phoneNumber.replace(/\D/g, '');
    if (cleaned.startsWith('55') && (cleaned.length === 12 || cleaned.length === 13)) {
      const numberWithoutCountryCode = cleaned.substring(2);
      if (numberWithoutCountryCode.length === 11) {
        return numberWithoutCountryCode.replace(/^(\d{2})(\d{5})(\d{4})$/, '+55 ($1) $2-$3');
      } else if (numberWithoutCountryCode.length === 10) {
        return numberWithoutCountryCode.replace(/^(\d{2})(\d{4})(\d{4})$/, '+55 ($1) $2-$3');
      } else {
        return phoneNumber;
      }
    } else {
      return phoneNumber;
    }
  };

  const safeLocaleCompare = (a, b) => {
    if (a == null) return -1;
    if (b == null) return 1;
    return a.localeCompare(b);
  };

  const columns = [
    {
      title: "Nome",
      dataIndex: "nome",
      sorter: (a, b) => safeLocaleCompare(a.nome, b.nome),
    },
    {
      title: "Telefone",
      dataIndex: "telefone",
      sorter: (a, b) => safeLocaleCompare(a.telefone, b.telefone),
      render: (date) => formatarTelefone(date),
    },
    {
      title: "Origem",
      dataIndex: "telOrigem",
      sorter: (a, b) => safeLocaleCompare(a.telOrigem, b.telOrigem),
      render: (date) => formatarTelefone(date),
    },
    {
      title: "Última Interação",
      dataIndex: "ultimaInteracao",
      sorter: (a, b) => new Date(a.ultimaInteracao) - new Date(b.ultimaInteracao),
      render: (date) => format(new Date(date), "dd/MM/yyyy HH:mm"),
    },
  ];

  const handleInstanciaChange = (value) => {
    if(value != selectedInstancia){
      setArrayContatos([]);
      setSelectedRowKeys([]);
      setPagina(0);
      setBuscaDias('');
      setNomeOuTelefone('');
      form2.resetFields(['buscar']); 
      form1.resetFields(['dias']); 
    }
   
    if(value != null){
      setSelectedInstancia(value);
      buscarContatos(value);
      setTextoSelecao('');
    }

  };

  const formatDateString = (dateString) => {
    const date = parseISO(dateString);
    return format(date, "dd/MM/yyyy HH:mm");
  };

  const fetchData = async (page, pageSize) => {
    console.log('dentro da fetchData')
    const options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    let url = '';
if(selectedInstancia == 'TODOS'){



if(buscaDias){
  console.log(buscaDias);
  url = `https://deliverypdv.com.br/v1/contatos/inativos/${selectedInstancia.toLowerCase()}/${idLoja}/${buscaDias?buscaDias:0}?page=${page}&size=${pageSize}`;
}else if(nomeOuTelefone){
  console.log(nomeOuTelefone);
  url =    `https://deliverypdv.com.br/v1/contatos/pegarTelOuNome/${selectedInstancia.toLowerCase()}/${idLoja}/${nomeOuTelefone}?page=${page}&size=${pageSize}`;
}else{
    url = `https://deliverypdv.com.br/v1/contatos/todosLoja/${idLoja}?page=${page}&size=${pageSize}`;
}

  const response = await axiosInstance.get(
    url,
    options
  );
  const registrosAux = [];
  response.data.content.map((data) => {
    registrosAux.push({
      key: data.id,
      ...data
    });
  });
  return {
    content: registrosAux,
    totalElements: response.data.totalElements,
  };
}else{



  if(buscaDias){
    console.log(buscaDias);
    url = `https://deliverypdv.com.br/v1/contatos/inativos/${selectedInstancia.toLowerCase()}/${idLoja}/${buscaDias?buscaDias:0}?page=${page}&size=${pageSize}`;
  }else if(nomeOuTelefone){
    console.log(nomeOuTelefone);
    url =    `https://deliverypdv.com.br/v1/contatos/pegarTelOuNome/${selectedInstancia.toLowerCase()}/${idLoja}/${nomeOuTelefone}?page=${page}&size=${pageSize}`;
  }else{
      url = `https://deliverypdv.com.br/v1/contatos/todosOrigem/${selectedInstancia}/${idLoja}?page=${page}&size=${pageSize}`;
  }

  const response = await axiosInstance.get(
    url,
    options
  );
    console.log(response.data);
    const registrosAux = [];
    response.data.content.map((data) => {
      registrosAux.push({
        key: data.id,
        ...data
      });
    });
    return {
      content: registrosAux,
      totalElements: response.data.totalElements,
    };


}

  };

  return (
    <Container>
      <Row
        gutter={16}
        style={{
          justifyContent: "center",
          marginTop: "110px",
          marginBottom: "80px",
        }}
      >
        <Col xs={24} sm={12} style={{ textAlign: "center" }}>
          
          <Form.Item>
            <Select
              style={{ width: "200px" }}
              placeholder="Selecione uma linha..."
              value={selectedInstancia}
              onChange={handleInstanciaChange}
              loading={loading}
            >
              <Option key={'todos'} value={'TODOS'}>{'       Todos       '}</Option>
              {instancias.map((opcao) => (
                <Option key={opcao.id} value={opcao.tel} >
                  {formatarTelefone(opcao.tel)}
                </Option>
              ))}
            </Select>
          </Form.Item> 
        </Col>
        <Col xs={24} sm={24} style={{ textAlign: "center" }}>
          <Card>
            <Form
              name="basic"
              layout="horizontal"
              initialValues={{ remember: true }}
              form={form1}
              onFinish={async (dados) => {
                try {
                  form2.resetFields(['buscar']); 
                  console.log(dados);
                  setLoading(true);
                  const options = {
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      Authorization: "Bearer " + accessToken,
                    },
                  };
                  setPagina(0);
                  const response1 = await axiosInstance.get(
                    `https://deliverypdv.com.br/v1/contatos/inativos/${selectedInstancia.toLowerCase()}/${idLoja}/${dados.dias?dados.dias:0}?page=${0}&size=${20}`,
                    options
                  );

                  const registrosAux = [];
                  response1.data.content.map((data) => {
                    registrosAux.push({
                      key: data.id,
                      ...data
                    });
                  });
                  setRegistros({ 
                    content: registrosAux,
                    totalElements: response1.data.totalElements
                  });

                  setPagination({
                    current: response1.data.pageable.pageNumber + 1,
                    pageSize: response1.data.pageable.pageSize,
                    total: response1.data.totalElements,
                  });
                  setArrayContatos([]);
                  setSelectedRowKeys([]);
                  setLoading(false);
                  setTextoSelecao(`e Sem interação há ${dados.dias?dados.dias:0} dia(s)`);
                  setBuscaDias(dados.dias);
                  setNomeOuTelefone('');


                } catch (error) {
                  console.error('Erro ao chamar a API:', error.status);
                  if (error.status === 404) {
                    console.error(`${error.status} Instancia não encontrada`);
                  }
                  setLoading(false);
                }
              }}
              onFinishFailed={(errorInfo) => { console.log('Failed:', errorInfo); }}
              autoComplete="off"
            >
              <Col xs={24} sm={24} style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', justifyContent: 'center' }}>
                <Form.Item
                  name="dias"
                >
                  <InputNumber
                    placeholder="Não Falo há"
                    style={{ width: '100%' }}
                    rules={[{ required: true, message: 'Por favor, insira um número' }]}
                  />
                </Form.Item>
                <Button type="primary" style={{backgroundColor:'#000' }} loading={loading}  htmlType="submit">Consultar</Button>
              </Col>
            </Form>

            <Form
              name="basic"
              layout="horizontal"
              form={form2}
              initialValues={{ remember: true }}
              onFinish={async (dados) => {
                if(dados.buscar){
                  setTelefoneOuNomeTodos(dados.buscar);
                try {
                  form1.resetFields(['dias']); 
                  console.log(dados);
                  setLoading(true);
                  const options = {
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      Authorization: "Bearer " + accessToken,
                    },
                  };
                  setPagina(0);
                  const response1 = await axiosInstance.get(
                    `https://deliverypdv.com.br/v1/contatos/pegarTelOuNome/${selectedInstancia.toLowerCase()}/${idLoja}/${dados.buscar}`,
                    options
                  );
                  const registrosAux = [];
                  response1.data.content.map((data) => {
                    registrosAux.push({
                      key: data.id,
                      ...data
                    });
                  });
                  setRegistros({ 
                    content: registrosAux,
                    totalElements: response1.data.totalElements
                  });



                  setPagination({
                    current: response1.data.pageable.pageNumber + 1,
                    pageSize: response1.data.pageable.pageSize,
                    total: response1.data.totalElements,
                  });

                  setBuscaDias('');
                  setNomeOuTelefone(dados.buscar);
                  setArrayContatos([]);
                  setSelectedRowKeys([]);
                  setLoading(false);
                  setTextoSelecao(dados.buscar? `e que contém a expressão "${dados.buscar}"`:'');
                  
                } catch (error) {
                  console.error('Erro ao chamar a API:', error.status);
                  if (error.status === 404) {
                    console.error(`${error.status} Instancia não encontrada`);
                    setRegistros({ 
                      content: [],
                      totalElements: 0
                    });
                  }
                  setLoading(false);
                }
              }
              }}
              onFinishFailed={(errorInfo) => { console.log('Failed:', errorInfo); }}
              autoComplete="off"
            >
              <Col xs={24} sm={24} style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', justifyContent: 'center' }}>
                <Form.Item
                  name="buscar"
                >
                  <Input
                    placeholder="Nome/Telefone" 
                    style={{ width: '100%' }} 
                  />
                </Form.Item>
                <Button
                  style={{backgroundColor:'#000' }}
                  type={'primary'}
                  htmlType={'submit'}
                  loading={loading}
                >
                  Consultar
                </Button>
              </Col>
            </Form>
          </Card> 
          <Card>
            <Col xs={24} sm={24} style={{ textAlign: 'center' }}>
            <strong> Seleção: {`${selectedInstancia == 'TODOS'? selectedInstancia: selectedInstancia ? formatarTelefone(selectedInstancia):''} ${textoSelecao} `}</strong>
            </Col>
            <Col xs={24} sm={24} style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', justifyContent: 'center' }}>

            <Button type="primary" style={{backgroundColor:'#000' }} loading={loading} htmlType="Button" onClick={async () => {
                console.log('limpar seleção');
                setArrayContatos([]);
                setSelectedRowKeys([]);
                setRegistros([]);
                setSelectedInstancia(null);
                setPagination({
                  current: 1,
                  pageSize: 20,
                  total: 0,
                });
                form1.resetFields(['dias']); 
                form2.resetFields(['buscar']); 
                setTextoSelecao('');            
              }}> Limpar Seleção
              </Button>
              <div>
          <Modal
            title="Confirmação"
            visible={visibleExcluir}
            onOk={() => {
              console.log("Operação efetivada");
              const options = {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + accessToken,
                },
                data: arrayContatos 
              };
              setLoading(true);
              console.log(arrayContatos);
              
              axiosInstance.delete(
                `https://deliverypdv.com.br/v1/contatos`,
                options   
              ).then((response) => {
                console.log(response.status);
           
                if(response.status == 200){
                  notification.success({
                    message: "Sucesso",
                    description: "Exclusão feita!",
                  }); 
                  setArrayContatos([]);
                  setSelectedRowKeys([]);
                  buscarContatos(selectedInstancia);
                }else{
                  notification.error({
                    message: "Falha",
                    description: "Falha na exclusão!",
                  });
                  setLoading(false);  
                }

              }).catch((error) => {
                console.error('Error:', error);
                notification.error({
                  message: "Falha",
                  description: "Falha na exclusão!",
                });
                setLoading(false);  
              });
              setVisibleExcluir(false);
            }}
            onCancel={() => setVisibleExcluir(false)}
            okText="Confirmar"
            cancelText="Cancelar"
          >
            <p>Deseja Mesmo Excluir?</p>
          </Modal>
          <Modal
            title="Confirmação"
            visible={visibleBloquear}
            onOk={() => {
              const options = {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + accessToken,
                },
              };
              setLoading(true);
              console.log(arrayContatos);
              
              axiosInstance.put(
                `https://deliverypdv.com.br/v1/contatos/bloquear`,arrayContatos,
                options   
              ).then((response) => {
                console.log(response.status);
           
                if(response.status == 200){
                  notification.success({
                    message: "Sucesso",
                    description: "Bloqueio feito!",
                  }); 
                  setArrayContatos([]);
                  setSelectedRowKeys([]);
                  buscarContatos(selectedInstancia);
                }else{
                  notification.error({
                    message: "Falha",
                    description: "Falha no bloqueio!",
                  });
                  setLoading(false);  
                }

              }).catch((error) => {
                console.error('Error:', error);
                notification.error({
                  message: "Falha",
                  description: "Falha no bloqueio!",
                });
                setLoading(false);  
              });
              setVisibleBloquear(false);
            }}
            onCancel={() => setVisibleBloquear(false)}
            okText="Confirmar"
            cancelText="Cancelar"
          >
            <p>Deseja Mesmo Bloquear?</p>
          </Modal>
    </div>
              <Button type="primary"      
                    style={{
                    backgroundColor: 'black',
                    borderColor: 'black',
                    color: 'white',
                    opacity:  arrayContatos.length == 0? 0.5 : 1 ,
                    pointerEvents: arrayContatos.length == 0? true : false ? 'none' : 'auto', 
                  }}  
                    disabled={arrayContatos.length == 0? true : false}  loading={loading} htmlType="Button" onClick={async () => {
                      setVisibleExcluir(true);
        
              }}> Excluir Contatos 
              </Button>

              <Button type="primary"      
                    style={{
                    backgroundColor: 'black',
                    borderColor: 'black',
                    color: 'white',
                    opacity:  arrayContatos.length == 0? 0.5 : 1 ,
                    pointerEvents: arrayContatos.length == 0? true : false ? 'none' : 'auto', 
                  }}  
                    disabled={arrayContatos.length == 0? true : false}  loading={loading} htmlType="Button" onClick={async () => {
                      setVisibleBloquear(true);
              }}> Bloquear Contatos 
              </Button>
              
         
      <Checkbox 
        onChange={onChangeTodos} 
        style={{ 
          border: '1px solid #000', 
          borderRadius: '4px', 
          padding: '8px', 
          display: 'flex',
          backgroundColor: '#000',
          color: '#fff',
          flexDirection: 'row-reverse'
        }}
      >
        Todos
      </Checkbox>
   
            </Col>
            <strong>Aviso: As funções abaixo afetam apenas os contatos na lista deste aplicativo e, em nenhum momento, vão afetar os contatos reais no telefone/WhatsApp do proprietário.</strong>
          </Card> 
          <br />
          <Card>
            <Table
               rowSelection={checkboxTodos?null:{
                selectedRowKeys,
                onChange: onSelectChange,
              }}
              columns={columns}
              dataSource={registros.content}
              loading={loading}       
              pagination={!checkboxTodos ? pagination: null}
              onChange={handleTableChange}
      
            />
          </Card>
          <Card>
          <strong>{arrayContatos.length} contato(s) selecionado(s) do total de {pagination.total} contato(s) ativo(s)</strong>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default CrudClube;

const Container = styled.div`
  padding: 20px;
`;

