import React, { useState, useEffect, useCallback } from "react";
import camuflado from "../../../fundo.webp";
import styled from "styled-components";
import { Button, Input, Form, Row, Col, Card, message } from 'antd';
import TextArea from "antd/es/input/TextArea";
import axiosInstance from "../../../axiosInterceptor";
import UploadImg from "../../../UploadImg";
import botApiUrl from "../../../botApiUrl";

const ClubeForm = ({ onTabChange, setarReload,  arrayContatos, pagination }) => {
  const accessToken = window.localStorage.getItem("accessToken");
  const idUser = window.localStorage.getItem("idUser");
  const [loading, setLoading] = useState(false);

  const [messageContent, setMessageContent] = useState('');
  const [idLoja, setIDLoja] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [instancias, setInstancias] = useState([]);

  const [mensagem, setMensagem] = useState('');
  const [telefonePrincipal, setTelefonePrincipal] = useState('');
  const [clientIdPrincipal, setClientIdPrincipal] = useState('');

  const [textoEditado, setTextoEditado] = useState(false);

  const [recarregar, setRecarregar] = useState(false);

  useEffect(() => {
    if (idLoja && accessToken) {
      buscarDados();
    }
  }, [accessToken, idLoja]);

  const base64ToFile = (base64, filename, mimeType) => {
    console.log(base64);
    const arr = base64.split(',');
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, { type: mimeType });
  };

  const buscarDados = async() => {
    setLoading(true);
    const options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    try {
      const resp = await botApiUrl.get(
        `/v1/sessao/buscarPorIdLojaLogado/${idLoja}`,
        options
      );
      setInstancias(resp.data);
      setLoading(false);

      for(const instancia of resp.data) {
        if(instancia.principal == 1) {
          if(instancia.mensagem) {
            setTelefonePrincipal(instancia.tel);
            setClientIdPrincipal(instancia.idInstancia);
            form.setFieldsValue({
              mensagem: JSON.parse(instancia.mensagem).mensagem,
            });
            setMensagem(JSON.parse(instancia.mensagem).mensagem);
            
            const arrayImagensString = JSON.parse(instancia.mensagem).imagem;
            if(arrayImagensString.length > 0) {

              const arrayImagens = JSON.parse(arrayImagensString[0]);
              console.log(arrayImagens);
              const initialFileList = arrayImagens.map((image, index) => {
                const file = base64ToFile(image.base64, image.name, image.type);
                return {
                  ...image,
                  originFileObj: file, 

                };
              });
              setFileList(initialFileList);
            }

            setMensagem(instancia.mensagem);
          }
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!idUser) return;

    const fetchData = async () => {
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      };
      if (!idLoja) {
        const resp = await axiosInstance.get(`https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`, options);
        setIDLoja(resp.data.id);
      }
    };
    fetchData();
  }, [idUser]);

  const handleTextAreaChange = (event) => {
    //setMessageContent(event.target.value);
    console.log(event.target.value);
    setTextoEditado(true);
  };

  const [form] = Form.useForm();
  return (
    <Row
      style={{
        justifyContent: "center",
        marginTop: "110px",
        marginBottom: "80px",
      }}
    >
      <Col xs={24} sm={20} md={16} lg={10} xl={10}>
        <Card title="Salvar Mensagem" bordered={false}>
          <Form layout="vertical" form={form} onFinish={async (dados) => {
            if (dados.mensagem != undefined) {
              if (dados.mensagem.trim() === '') {
                message.error('A mensagem não pode estar vazia');
              } else {
                const arrayDeImagens = [];
                console.log(fileList);
              
                fileList.map((imagem) => {
                  arrayDeImagens.push(JSON.stringify(imagem));
                });
                const body = {
                  contatos: arrayContatos,
                  mensagem: dados.mensagem,
                  imagem: [JSON.stringify(fileList)]
                };

                try {
                  setLoading(true);
                  const options = {
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      Authorization: "Bearer " + accessToken,
                    },
                  };

                const resp =  await axiosInstance.post(
                    `https://deliverypdv.com.br/v1/sessao/inserirMsg/${idLoja}`, body,
                    options
                  )
                    console.log(resp.data);
                    if (resp.status == 200) {
                      message.success('Mensagem salva!');
                      setRecarregar(!recarregar);
                     await buscarDados();
                      // setLoading(false);
                    }
             

                } catch (error) {
                  console.error('Erro ao chamar a API:', error.status);
                  if (error.status === 404) {
                    console.error(`${error.status}`);
                    message.error('Erro ao gravar a mensagem!');
                  }
                  setLoading(false);
                }
              }
            } else {
              message.error('A mensagem não pode estar vazia');
            }
          }}>
            <Form.Item label="Sua Mensagem" name="mensagem">
              <TextArea
                rows={4}
                placeholder="Digite sua mensagem aqui..."
                onChange={handleTextAreaChange}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit"
                style={{
                  backgroundColor: 'black',
                  borderColor: 'black',
                  color: 'white',
                  opacity: arrayContatos.length == 0 ? 0.5 : 1,
                  pointerEvents: arrayContatos.length == 0 ? 'none' : 'auto',
                }}
                disabled={arrayContatos.length == 0 ? true : false}
                loading={loading}
              >
                Salvar Mensagem
              </Button>

              <Button type="primary" htmlType="button"
                style={{
                  backgroundColor: 'black',
                  borderColor: 'black',
                  color: 'white',
                  opacity: !mensagem  ? 0.5 : 1,
                  pointerEvents: !mensagem  ? 'none' : 'auto',
                }}
                disabled={!mensagem  ? true : false}
                loading={loading}
                onClick={async () => {
                  setLoading(true);
                  const options = {
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      Authorization: "Bearer " + accessToken,
                    },
                  };

                  const body = { comando: JSON.stringify({ comando_string: "ENVIAR_MSG_TXT_DIRETA", parametros_string: [clientIdPrincipal, telefonePrincipal, "!techsbot todosenviarmensagem"] }), nodeServer:`${window.localStorage.getItem("server")}` };
                  const resp = await axiosInstance.post(
                    `https://deliverypdv.com.br/v1/pilha-comandos/pushComNodeServer`, body,
                  //`http://localhost:3010/v1/pilha-comandos/pushComNodeServer/`, body,
                    options
                  );
                  console.log(resp.status);
                  if (resp.status == 201) {
                    message.success('Mensagem enviada!');
                  } else {
                    message.error('Houve uma falha ao tentar enviar a mensagem.');
                  }
                  setLoading(false);
                }}
              >
                Enviar Mensagem
              </Button>

              <Button type="primary" htmlType="button"
                style={{
                  backgroundColor: 'black',
                  borderColor: 'black',
                  color: 'white',
                  opacity: !mensagem ? 0.5 : 1,
                  pointerEvents: !mensagem ? 'none' : 'auto',
                }}
                disabled={!mensagem ? true : false}
                loading={loading}
                onClick={async () => {
                  console.log(form.getFieldValue('mensagem'));
                  const options = {
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      Authorization: "Bearer " + accessToken,
                    },
                  };
                  setLoading(true);
                  console.log(arrayContatos);

                  const resp = await axiosInstance.put(
                    `https://deliverypdv.com.br/v1/sessao/deletarMensagens/${idLoja}`, {},
                    options
                  );

                  if (resp.status == 200) {
                    form.resetFields(['mensagem']);
                    setMensagem(null);
                    setFileList([]);
                    message.success('Mensagem limpa!');
                  } else {
                    message.error('Houve uma falha ao tentar limpar a mensagem.');
                  }
                  setLoading(false);
                }}
              >
                Limpar Mensagem
              </Button>
            </Form.Item>

            <UploadImg fileList={fileList} setFileList={setFileList} ></UploadImg>
          </Form>
        </Card>
        <Card>
          <strong>{arrayContatos.length} contato(s) selecionado(s) do total de {pagination.total} contato(s) ativo(s)</strong>
        </Card>
      </Col>
    </Row>
  );
};

const Container = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
  background-color: #484848;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-image: url(${camuflado});
`;

export default ClubeForm;
